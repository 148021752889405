// src/utils/types.ts
import React, { useState, useRef } from 'react';
import { useCart } from '../context/cartContext';
import { fetchProductsModifiers } from '../utils/api';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify'; // Import toast



export interface appConfig {
    id: number,
    // tenant_id: f03d0f4e-366c-4e78-b9a3-eeef78d62197,
    title: string,
    shortname: string,
    first_name: string,
    last_name: string,
    identity_no: string,
    companyreg: string,
    tin: string,
    active: true,
    address: string,
    address_2: string,
    address_3: string,
    city: string,
    state: string,
    country: string,
    zip: string,
    phone: number,
    email: string,
    remark: string,
    domain: string,
    // db_database: string,
    // db_username: string,
    valid_starts: string,
    valid_ends: string,
    sst: number,
    service_charge: number,
    gst: number,
    // deleted_at: null,
    // created_at: 2024-07-02T08:26:12.000000Z,
    // updated_at: 2024-09-10T09:53:57.000000Z
}

// src/types/config.d.ts
export interface AppConfig {
    API_URL: string;
    COMPANY_NAME: string;
    DOMAIN_NAME: string;
    DOMAIN_URL: string;
    TENANT_ID: number;
    API_KEY: string;
}

export interface ProductCardProps {
    product: Product;
    imageRef?: React.RefObject<HTMLImageElement>; // Add optional imageRef here
    
}

export interface Category {
    id: number;
    cat_name: string;
    cat_prod_qty: number;
}

// /src/utils/types.ts
export interface Brand {
    id: number;
    cat_name: string;
    brand_prod_qty: number;
}

export interface Media {
    id: number;
    original_url: string;
    preview_url: string;
    // Add other properties from media if needed
};

export interface Product {
    id: number;
    media: Media[]; // Update this to be an array of Media objects
    original_url: string;
    preview_url: string;
    product_code: string;
    product_name: string;
    product_desc: string;
    product_value: number;
    brand: number;
    MTPrice: number;
    MTPriceArray: { price: number; cat_name: string }[]; // Updated structure
    product_cat: number;
    cat_name: string;
    brand_name: string;
    product_link: string;
    details: string;
    warranty: string;
    stock: number;
    restock: number;
    restock_at: string;
    // Add any other properties your product may have
};

export const displayPrice = (price: number) => {
    // Convert price to a number if it's not already
    const numericPrice = typeof price === 'number' ? price : parseFloat(price);

    // Check if numericPrice is valid
    if (!isNaN(numericPrice)) {
        return 'RM' + numericPrice.toLocaleString('en-US', { minimumFractionDigits: 2 }); // Format to 2 decimal places
    }

    return 'N/A'; // Return a fallback value if price is invalid
};

export const formatProductNameForUrl = (name: string) => {
    return name
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')  // Replace non-alphanumeric characters with hyphens
        .replace(/-+$/, '');          // Remove trailing hyphens
};

export interface CartItem {
    cartItemId: string; // Unique ID for each cart item
    id: number; // product id
    product_category: number;
    product_id: number;
    product_code: string;
    product_name: string;
    product_value: number;

    price: number;
    quantity: number;
    payment_for: string;
    modifiers: Modifier[]; // Add modifiers to the cart item
    // imageRef?: React.RefObject<HTMLImageElement>; // Add imageRef to CartItem
}

// New Modifier interface 250323
export interface Modifier {
    id: number;
    product_id: number;
    product_name: string;
    product_value: number;
    // Add other properties as needed

    // id: number;
    user_id: number;
    media_id: number | null;
    product_uuid: string;
    product_code: string;
    product_code1: string | null;
    product_code2: string | null;
    product_code3: string | null;
    // product_name: string;
    product_desc: string | null;
    product_type: string | null;
    product_cat: string;
    product_addon_cat: number[];  //string[];
    product_unit_m: string;
    // product_value: string;
    product_link: string | null;
    product_path: string | null;
    brand: string | null;
    warranty: string;
    details: string | null;
    stock: number | null;
    restock: number | null;
    restock_at: string;
    active: boolean;
    cond: boolean;
    is_modifier: boolean;
    vat_active: boolean;
    approved_by: string | null;
    approved_at: string;
    remark: string | null;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    vat_price: string;
    vat_tax: string;
}

export interface CartState {
    items: CartItem[];
}

export interface CartAction {
    type: string;
    payload: CartItem | CartItem[];
}

export interface CartProviderProps {
    children: React.ReactNode;
}

// New type for Product with imageRef
export interface ProductWithImageRef extends Product {
    imageRef?: React.RefObject<HTMLImageElement>;
}

export interface ProductWithImageRef extends Product {
    imageRef?: React.RefObject<HTMLImageElement>;
}

export const useHandleAddToCart = () => {

    const { dispatch } = useCart();
    const [showModifierModal, setShowModifierModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<ProductWithImageRef | null>(null);
    const [modifiers, setModifiers] = useState<Modifier[]>([]);
    const [selectedModifiers, setSelectedModifiers] = useState<Modifier[]>([]);
    
    const [animationQueue, setAnimationQueue] = useState<{ product: ProductWithImageRef, imageRef: React.RefObject<HTMLImageElement> }[]>([]);


    const handleAddToCart = async (product: Product, imageRef?: React.RefObject<HTMLImageElement>) => {
        try {
            // Fetch modifiers for the product
            const response = await fetchProductsModifiers(product.id, 2); // Assuming 2 is the add_on category id
            // console.log('typesp ln 118 response.data', response.data);

            if (response.data && response.data.length > 0) {
                // If modifiers exist, show the modal
                setModifiers(response.data);
                setSelectedProduct({ ...product, imageRef }); // Add imageRef to the product
                setShowModifierModal(true);
            } else {
                // If no modifiers, add the product directly to the cart
                // console.log('typesp ln 171 product', product, imageRef);
                // console.log('useHandleAddToCart: handleAddToCart called');
                // console.log('useHandleAddToCart: handleAddToCart imageRef:', imageRef);

                if (imageRef) {
                    addToCart({ ...product, imageRef }, [], imageRef); // Pass imageRef here
                // } else {
                //     addToCart({ ...product }, [], undefined); // Pass undefined if no imageRef
                    // console.log('typesp ln 175 product', product);
                }
            }
        } catch (error) {
            console.error('Error fetching modifiers:', error);
            // Handle error (e.g., show an error message)
            if (imageRef) {
                addToCart({ ...product, imageRef }, [], imageRef); // still add to cart even error
            } else {
                addToCart({ ...product }, [], undefined); // Pass undefined if no imageRef
            }
        }
    };

    const handleCloseModifierModal = () => {
        setShowModifierModal(false);
        setSelectedProduct(null);
        setModifiers([]);
        setSelectedModifiers([]);
    };

    const handleModifierSelection = (modifier: Modifier) => {
        // Toggle selection
        setSelectedModifiers((prevSelected) => {
            const isSelected = prevSelected.some((m) => m.id === modifier.id);
            if (isSelected) {
                return prevSelected.filter((m) => m.id !== modifier.id);
            } else {
                return [...prevSelected, modifier];
            }
        });
    };

    const handleConfirmModifiers = () => {
        if (selectedProduct) {
            if (selectedProduct.imageRef) {
                addToCart(selectedProduct, selectedModifiers, selectedProduct.imageRef);
            // } else {
            //     addToCart(selectedProduct, selectedModifiers, undefined);
            }
        }
        handleCloseModifierModal();
    };

    const addToCart = (product: ProductWithImageRef, modifiers: Modifier[], imageRef?: React.RefObject<HTMLImageElement>) => {
        
        // console.log('useHandleAddToCart: addToCart called');
        // console.log('useHandleAddToCart: addToCart imageRef:', imageRef);
        
        // Calculate the total price including modifiers
        let modifierTotalPrice = 0;
        let hasCondTrue = false;

        modifiers.forEach(modifier => {
            if (modifier.cond) {
                if (!hasCondTrue) {
                    modifierTotalPrice += modifier.product_value;
                    hasCondTrue = true;
                }
            } else {
                modifierTotalPrice += modifier.product_value;
            }
        });
        // console.log('typesp ln 252 modifierTotalPrice', product.MTPrice, modifierTotalPrice);

        const totalPrice = Number(product.MTPrice) + modifierTotalPrice;


        // console.log('typesp ln 204 modifierTotalPrice', modifierTotalPrice);
        // console.log('typesp ln 205 totalPrice', totalPrice);
        // console.log('typesp ln 228 imageRef', imageRef);

        dispatch({
            type: 'ADD_TO_CART',
            payload: {
                id: product.id,
                product_category: product.product_cat,
                product_code: product.product_code,
                product_name: product.product_name,
                product_value: product.MTPrice,

                price: totalPrice, // Use the total price including modifiers
                modifiers: modifiers, // Add selected modifiers to the cart item
                payment_for: 'product', // Add payment_for

                // imageRef: imageRef, // No need to pass imageRef here

            },
        });
        if (imageRef) {
            setAnimationQueue(prevQueue => [...prevQueue, { product, imageRef }]);
            // console.log('useHandleAddToCart: animationQueue updated', animationQueue);
        }


        toast.info(product.product_name + ' added to cart!', {
            position: "bottom-left",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });;
        // alert(product.product_name + ');
        
    };

    return {
        handleAddToCart,
        showModifierModal,
        handleCloseModifierModal,
        modifiers,
        selectedProduct,
        handleModifierSelection,
        handleConfirmModifiers,
        selectedModifiers,
        animationQueue,

    };
};

export interface Customer {
    id: number;
    name: string;
    email: string;
    // other fields
}

export interface OrderSummary {
    id: number;
    vendor_id: number; // owner shop id
    customer_id: number | null;
    order_type: string;
    table_id: number | null;
    items: OrderItem[]; // u8 Tranx id related to this order

    status: string;

    amount_to_pay: string;
    user_id: number;
    // table: TableDetails | null;

    total_taxes: string | null;
    receivable: string | null;
    rounding_value: string | null;
    rounded_receivable: string | null;
    received_amount: string | null;
    change: string | null;
    service_charges: string | null;
    total_amount: string | null;

    totalAmount: number;
    totalWithTax: number;
    totalTax: number;
    roundingDifference: number;

    created_at: string;
    updated_at: string;

    //abb 22-1-25
    balance: number;

    // process_log: string; // New field for process log


}

//abb 22-1-25
export interface OrderItem {

    // id: number;
    order_id: number;
    customer_id: number;

    product_id: number;
    product_code: string;

    product_name: string;
    product_category: number;
    quantity: number;
    product_value: number;

    price: number;
    total_price: number;
    // discount: string;
    // tax: string;
    // created_at: string;
    // updated_at: string;
    // deleted_at: string | null;

    // id: number;
    // // vendor_id: number;
    // order_id: number;
    // product_id: number;
    // product_name: string;
    // product_category: number;
    // price: string;
    // quantity: number;
    // total_price: string;
    // modifires: string;
    // // add_ons: string;
    // // toppings: string;
    // status: string;
    // items: string;
    // created_at: string;
    // updated_at: string;
    // // product: ProductDetails;
    // // specifications: Specification[];

    //abb 21-1-25
    payment_desc: string;
    payment_for: string;
    balance: number;
    unit_price: number;
    qty: number;
    tranx_details: string;
    category_id: number;

    // //abb 250214
    // print_to_location: string;

    modifiers: Modifier[]; // Add modifiers to OrderItem
    checkout: boolean;


}
