import React, { createContext, useReducer, useContext, useEffect, useState } from 'react';
import { CartItem, CartState, CartAction, CartProviderProps } from '../utils/types';
import { v4 as uuidv4 } from 'uuid'; // Import uuid


const CartContext = createContext<CartState & { loading: boolean, dispatch: React.Dispatch<CartAction> } | any>(null);

const cartReducer = (state: CartState, action: CartAction): CartState => {
    let newState: CartState;
    switch (action.type) {
        case 'ADD_TO_CART':
            const newItem = action.payload as CartItem;
            // Generate a unique cartItemId based on product ID and modifier IDs
            const modifierIds = newItem.modifiers.map(m => m.id).sort().join('-');
            const cartItemId = `${newItem.id}-${modifierIds}`;

            // // Calculate the total price including modifiers
            // const modifierTotalPrice = newItem.modifiers.reduce((sum, modifier) => sum + Number(modifier.product_value), 0);
            // console.log('ccp ln 19 modifierTotalPrice >>>>', modifierTotalPrice);
            // const totalPrice = Number(newItem.price) + modifierTotalPrice;

            // Check if an item with the same cartItemId already exists
            const existingItem = state.items.find(item => item.cartItemId === cartItemId);

            if (existingItem) {
                // If it exists, increment the quantity
                newState = {
                    ...state,
                    items: state.items.map(item =>
                        item.cartItemId === cartItemId
                            ? { ...item, quantity: item.quantity + 1 }
                            : item
                    ),
                };
            } else {
                // If it doesn't exist, add a new item with the generated cartItemId
                newState = {
                    ...state,
                    items: [...state.items, { ...newItem, cartItemId, quantity: 1 }],
                };
            }
            break;
        case 'REMOVE_FROM_CART':
            newState = {
                ...state,
                items: state.items.filter(item => item.cartItemId !== (action.payload as CartItem).cartItemId),
            };
            break;
        case 'CLEAR_CART':
            newState = {
                ...state,
                items: [],
            };
            break;
        case 'LOAD_CART':
            newState = {
                ...state,
                items: action.payload as CartItem[],
            };
            break;
        default:
            newState = state;
    }
    // Save to localStorage immediately after state change
    localStorage.setItem('cart', JSON.stringify(newState.items));
    return newState;
};

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
    const initialState: CartState = { items: [] };
    const [state, dispatch] = useReducer<React.Reducer<CartState, CartAction>>(cartReducer, initialState);
    const [loading, setLoading] = useState(true); // Add loading state

    // Load cart state from local storage when the app initializes
    useEffect(() => {
        const storedCart = localStorage.getItem('cart');
        // console.log('cartCont ln 53 storedCart >>>>', storedCart);
        if (storedCart) {
            const parsedCart = JSON.parse(storedCart) as CartItem[];
            if (parsedCart) {
                dispatch({ type: 'LOAD_CART', payload: parsedCart });
            }
        }
        setLoading(false); // Set loading to false after attempting to load from local storage
    }, []);

    // // Save cart state to local storage whenever it changes
    // useEffect(() => {
    //     localStorage.setItem('cart', JSON.stringify(state.items));
    // }, [state.items]);

    return (
        <CartContext.Provider value={{ state, dispatch, loading }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);
