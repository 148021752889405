// src/pages/OrderDetails.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOrderDetails, updateOrder, getOrderProcessLog } from '../utils/api'; // Import updateOrder
import { displayPrice, Modifier, OrderSummary, Customer } from '../utils/types'; // Import types
import useCancelOrder from '../hooks/useCancelOrder';
import { hasRole } from '../utils/auth';
import { CustomerContext, CustomerContextProps } from '../context/customerContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify'; // Import toast

const OrderDetails: React.FC = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const userId = user.id;
    const { customers } = React.useContext(CustomerContext) as CustomerContextProps;
    const { orderId } = useParams<{ orderId: string }>();
    const [order, setOrder] = useState<OrderSummary | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { handleCancelOrder, isCancelling } = useCancelOrder();
    const [isProcessing, setIsProcessing] = useState<boolean>(false); // New state for processing
    const [orderProcessLog, setOrderProcessLog] = useState<any[]>([]); // New state for order process log

    useEffect(() => {
        const fetchOrder = async () => {
            setLoading(true);
            setError(null);
            try {
                if (!orderId) {
                    throw new Error('Order ID is missing');
                }
                const orderData = await fetchOrderDetails(parseInt(orderId));
                setOrder(orderData);
            } catch (err: any) {
                setError(err.message || 'Failed to fetch order details');
            } finally {
                setLoading(false);
            }
        };

        fetchOrder();
    }, [orderId]);

    useEffect(() => {
        if (order && !hasRole(['super_admin', 'staff']) && order.user_id !== userId) {
            setError('You do not have permission to view this order.');
            setLoading(false);
        }
    }, [order, userId]);

    // Function to update the order status in the local state
    const updateOrderStatus = (updatedOrder: OrderSummary) => {
        setOrder(prevOrder =>
            prevOrder && prevOrder.id === updatedOrder.id ? { ...prevOrder, status: updatedOrder.status } : prevOrder
        );
    };

    useEffect(() => {
        const fetchOrderProcessLog = async () => {
            if (!orderId) return;

            try {
                const response = await getOrderProcessLog(parseInt(orderId));
                setOrderProcessLog(response.process_log); // Set the fetched process log
                // console.log('Fetched process log:', orderProcessLog);

            } catch (err: any) {
                console.error('Failed to fetch order process log:', err);
            }
        };

        fetchOrderProcessLog();
    }, [orderId]);

    // Function to handle order processing
    // const handleProcessOrder = async () => {
    const handleProcessOrder = async (status: string) => {
        if (!order || !status) return;
        // if (!order) return;

                // const orderItems = order.items.map((item) => {
                //     // Calculate the total price for each item including modifiers
                //     const modifierTotalPrice = item.modifiers.reduce((sum, modifier) => sum + Number(modifier.product_value), 0);
                //     const totalPrice = Number(item.product_value) + Number(modifierTotalPrice);

                //     return {
                //         order_id: order.id,
                //         customer_id: order.customer_id,
                //         category_id: item.category_id,
                //         product_category: item.product_category,
                //         product_id: item.product_id,
                //         product_name: item.product_name,
                //         quantity: item.qty,
                //         product_value: item.product_value,
                //         price: totalPrice, // Use the total price including modifiers
                //         total_price: totalPrice * item.qty,
                //         payment_for: item.payment_for,
                //         qty: item.qty,
                //         unit_price: item.product_value,
                //         balance: totalPrice * item.qty,
                //         tranx_details: item.tranx_details,
                //         modifiers: item.modifiers,
                //         checkout: item.checkout,
                //     };
                // });
        
                const orderPayload = {
                    customerId: order.customer_id,
                    orderType: 'order',
                    tableId: '1',
                    // items: orderItems, // Filter items with checkout = false
                    status: status,
                    amountToPay: order.balance,
                    userId: userId,
                    table: [],
                    customer_name: customers.find(c => c.id === order.customer_id)?.name || 'Unknown Customer',
                };
        
        setIsProcessing(true);
        try {
            const updatedOrderData = {
                status: status, // Or any other status you want to set
            };
            const response = await updateOrder(order.id, orderPayload);
            // console.log('Order updated:', response);

            // Update the order status in the local state
            updateOrderStatus({ ...order, status: status });

            toast.success(`Order ${order.id} has been processed!`, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } catch (error) {
            console.error('Error updating order:', error);
            toast.error(`Failed to process order ${order.id}. Please try again.`, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsProcessing(false);
        }
    };

    if (loading) {
        return <div className="container mx-auto p-4">Loading order details...</div>;
    }

    if (error) {
        return <div className="container mx-auto p-4 text-red-500">Error: {error}</div>;
    }

    if (!order) {
        return <div className="container mx-auto p-4">Order not found.</div>;
    }

    // console.log('Order:', order);
    // console.log('Order Process Log:', orderProcessLog); // Log the process log
    const refreshOrderProcessLog = async (orderId: string | undefined, setOrderProcessLog: React.Dispatch<React.SetStateAction<any[]>>) => {
        if (!orderId) return;

        try {
            const response = await getOrderProcessLog(parseInt(orderId));
            setOrderProcessLog(response.process_log); // Refresh the process log after processing
            toast.success('Process log refreshed!', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (err: any) {
            console.error('Failed to refresh process log:', err);
            toast.error('Failed to refresh process log. Please try again.', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };
    
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Order Details</h1>
            {hasRole(['super_admin', 'staff']) && (
                <button
                    className={`text-white bg-blue-600 mx-5 px-4 py-1 rounded-lg mb-4 ${isProcessing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}`}
                    onClick={async () => {
                        await handleProcessOrder('pending');
                        await refreshOrderProcessLog(orderId, setOrderProcessLog);
                    }}
                    disabled={isProcessing}
                >
                    {isProcessing ? 'Pending...' : 'Pending now by ' + userId}
                </button>
            )}
            {hasRole(['super_admin', 'staff']) && (
                <button
                    className={`text-white bg-green-600 mx-5 px-4 py-1 rounded-lg mb-4 ${isProcessing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}`}

                    onClick={async () => {
                        await handleProcessOrder('processing');
                        await refreshOrderProcessLog(orderId, setOrderProcessLog);
                    }}
                    disabled={isProcessing}
                >
                    {isProcessing ? 'Processing...' : 'Process now by ' + userId}
                </button>
            )}
            {order.status !== 'cancel' && (
                <button
                    onClick={() => handleCancelOrder(order.id, updateOrderStatus)}
                    className={`text-white bg-red-600 mx-5 px-4 py-1 rounded-lg mb-4 ${isProcessing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}`}
                    disabled={isCancelling}
                >
                    {isCancelling ? 'Cancelling...' : 'Cancel'}
                </button>
            )}
            {hasRole(['super_admin', 'staff']) && (
                <div className="md:fixed md:top-10 md:right-2 bg-pink-100 mb-4 p-4 border border-2 border-pink-200 ">
                    {orderProcessLog && orderProcessLog.length > 0 && (
                        <div> 
                            <h2 className="text-sm font-semibold mb-2">Order Process Log:</h2>
                            <button
                                className="text-white bg-blue-600 px-2 py-1 rounded-lg mb-2"
                                
                                onClick={async () => {
        await refreshOrderProcessLog(orderId, setOrderProcessLog);
    }}
                            >
                                Refresh Log
                            </button>
                            <ul>
                                {orderProcessLog
                                    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
                                    .map((log, index) => (
                                    <li key={index} className={`text-xs mb-2 ${index === 0 ? 'font-bold text-pink-500' : 'font-thin'}`}>
                                            <span className="">
                                                {new Date(log.timestamp).toLocaleString('en-GB', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: true
                                                })}&nbsp;|
                                                &nbsp;{log.action} - by {log.user_id}:{log.user_name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )}
            
            <div className="mb-4">
                <p>
                    <span className="font-semibold">Order ID:</span> {order.id}

                </p>
                <p>
                    <span className="font-semibold">Customer Name:</span>
                    &nbsp;{order.customer_id}&nbsp;:&nbsp;
                    &nbsp;{customers.find(c => c.id === order.customer_id)?.name || 'Unknown Customer'}
                    &nbsp;&lt;{customers.find(c => c.id === order.customer_id)?.email || 'Unknown Email'}
                    &gt;
                </p>
                <p>
                    <span className="font-semibold">Order Date:</span> {new Date(order.created_at).toLocaleString()}
                </p>
                <p>
                    <span className="font-semibold">Status:</span> <span className={`font-semibold 
                                        ${order.status ===
                            'pending' ? 'text-blue-500'
                            : order.status === 'processing' ? 'text-green-500'
                                : order.status === 'cancel' ? 'text-red-500'
                                    : order.status === 'completed' ? 'text-brown-500' : 'text-black'
                        }`}>{order.status.toUpperCase()}</span>
                </p>
                <p>
                    <span className="font-semibold">Total:</span> {displayPrice(Number(order.balance))}
                </p>

            </div>

            <h2 className="text-xl font-bold mb-2">Order Items ({order.items.length})</h2>
            <ol className="list-decimal list-inside divide-y divide-gray-200">
                {order.items.map((item, index) => (
                    <li key={index} className="py-4 flex items-center justify-between before:content-[counter(my-counter)] before:mr-4" style={{ counterIncrement: 'my-counter' }}>
                        <div className="flex-grow">
                            <p className="font-medium">
                                <Link to={`/product/${item.product_id}`} target='_blank'>
                                    {item.payment_for}
                                    <span className="text-xs md:text-xs text-gray-500 pl-2">sku: {item.payment_desc}</span>

                                </Link>
                                &nbsp;({displayPrice(Number(item.product_value))})
                            </p>
                            {typeof item.modifiers === 'string' && (item.modifiers = JSON.parse(item.modifiers))}
                            {item.modifiers.length > 0 && (
                                <div className='modifiers-div'>
                                    <ul className="list-disc list-inside ">
                                        {item.modifiers.map((modifier: Modifier) => (
                                            <li key={modifier.id} className="text-xs">
                                                {modifier.product_name}
                                                <span className="text-xs md:text-xs text-gray-500 pl-2">sku: {modifier.product_code}</span>

                                                ({displayPrice(modifier.product_value)})
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <p className="text-sm text-gray-500">
                                {displayPrice(item.unit_price)} x {item.qty} = {displayPrice(item.unit_price * item.qty)}
                            </p>
                        </div>
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default OrderDetails;
