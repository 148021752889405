// src/pages/ProductPage.tsx
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import hooks for routing
import { fetchProductById } from '../utils/api'; // Import the new function

import { Product, displayPrice, formatProductNameForUrl, useHandleAddToCart, Customer } from '../utils/types'; // Import the types
import ProductDetails from '../components/ProductDetails'; // Import the new component
import { IconContext } from "react-icons";
import { Tooltip as ReactTooltip } from "react-tooltip";

// import { FaArrowLeftLong, FaX, FaShieldHeart, FaLink, FaFacebook, FaTelegram, FaLine, FaTwitter, FaWhatsapp } from "react-icons/fa6";
import { FaShieldHeart, FaLink, FaFacebook, FaTelegram, FaLine, FaTwitter, FaWhatsapp } from "react-icons/fa6";
import { IoLogoWechat } from "react-icons/io5";

// Import react-helmet for SEO and Open Graph tags
import { Helmet } from 'react-helmet-async';
import ModifierModal from '../components/ModifierModal'; // Import your modal component

// interface ProductModalProps {
//     product: Product;
//     onClose: () => void;
// }

// const ProductPage: React.FC<ProductModalProps> = ({ product, onClose }) => {

const ProductPage: React.FC = () => {

    const user = JSON.parse(localStorage.getItem('user') || '{}'); // Get user ID from local storage
    // console.log('user >>>>>', user);
    const userId = user.id;
    // const customer: Customer | null = JSON.parse(localStorage.getItem('customer') || 'null'); // Get customer data from local storage
    // const customerId = customer ? customer.id : 0;

    const navigate = useNavigate(); // Hook for navigation
    const [product, setProduct] = useState<Product | null>(null);
    // const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const modalRef = useRef<HTMLDivElement>(null); // Create a ref for the modal
    const imageRef = useRef<HTMLImageElement>(null); // Create a ref for the image

    const { handleAddToCart, showModifierModal, handleCloseModifierModal, modifiers, selectedProduct, handleModifierSelection, handleConfirmModifiers, selectedModifiers } = useHandleAddToCart();

    // Function to handle back navigation
    // const handleBackNavigation = () => {
    //     const referrer = document.referrer;
    //     const isSameDomain = referrer && new URL(referrer).origin === window.location.origin;

    //     if (isSameDomain) {
    //         // Navigate back if the referrer is within the same domain
    //         navigate(-1);
    //     } else {
    //         // Otherwise, navigate to the home page
    //         navigate('/');
    //     }
    // };

    // const { id } = useParams<{ id: string }>(); // Get product ID from URL parameters
    const { productId, productName } = useParams<{ productId: string; productName?: string }>();
    // console.log('Invalid product ID', productId, productName);

    // Ensure the names match the route parameters exactly: productId and productName


    useEffect(() => {
        const parsedProductId = parseInt(productId || '', 10); // Parse productId once here

        const loadProduct = async () => {
            if (!isNaN(parsedProductId)) { // Check if parsedProductId is a valid number
                try {
                    const fetchedProduct = await fetchProductById(parsedProductId); // Fetch product details using parsedProductId
                    setProduct(fetchedProduct);
                } catch (err) {
                    setError('Failed to load product details');
                } finally {
                    setLoading(false);
                }
            } else {
                // console.log('Invalid product ID', parsedProductId, productId);
                setError('Product ID is not available');
                setLoading(false);
            }
        };

        loadProduct();
    }, [productId]);

    if (loading) return <div className="w-full h-full bg-white p-5">Loading...</div>;
    if (error) return <div>{error}</div>;

    // Social share URLs with the current page link
    // const currentUrl = encodeURIComponent(window.location.href);
    const shareText = encodeURIComponent(`Check out this product: ${product?.product_name}`);

    // Build the Open Graph and SEO tags dynamically based on the product
    const productUrl = window.location.href;
    const productImage = product?.media && product.media.length > 0 ? product.media[0].original_url : '../images/df.jpg';
    const productNameNormal = product?.product_name || 'Product';
    const productDescription = product?.product_desc || 'No description available';

    // const productUrlName = 'product/' + product?.id + '/' + formatProductNameForUrl(product?.product_name || 'Product');
    const productUrlName = encodeURIComponent(productUrl);
    const productUrlLink = product ? `${window.location.origin}/product/${product.id}/${formatProductNameForUrl(product.product_name)}` : '';



    return (
        <>
            {/* SEO and Open Graph meta tags using react-helmet */}
            <Helmet>
                <title>{productNameNormal} | u8.my</title>
                <meta name="description" content={productDescription} />

                {/* Open Graph tags */}
                <meta property="og:title" content={productNameNormal} />
                <meta property="og:description" content={productDescription} />
                <meta property="og:image" content={productImage} />
                <meta property="og:url" content={productUrl} />
                <meta property="og:type" content="product" />
                <meta property="og:site_name" content="u8.my" />

                {/* Twitter Card Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={productNameNormal} />
                <meta name="twitter:description" content={productDescription} />
                <meta name="twitter:image" content={productImage} />
            </Helmet>

            <div className="z-30 fixed top-10 flex items-center justify-center bg-black bg-opacity-50 product-modal">

                {/* Modal body */}
                <div ref={modalRef} className="w-screen h-screen overflow-y-auto bg-white p-1">

                    {/* Modal header */}
                    <div className='z-50 fixed top-0 w-full flex flex-rowjustify-between bg-white'>



                    </div>

                    <div className='mt-0 flex flex-col md:flex-row'>

                        <div className='w-full md:w-2/5 p-4'>

                            <h2 className="product-name w-full  top-8 left-0   text-xl font-bold mt-0 mb-5 px-4 py-4 pb-8">
                                <a href={`${productUrlLink}`}>
                                    {/* {productUrlLink} */}
                                    {product?.product_name}
                                </a>
                            </h2>

                            <div className="product-page-main-img-container
 md:sticky  shadow-lg rounded-xl">
                                {/* md:top-20 mt-28 */}
                                <img
                                    src={product?.media && product.media.length > 0 ? product.media[0].original_url : 'df.jpg'}
                                    alt={product?.product_name}
                                    style={{
                                        // width: '100vw',
                                        // height: '100vh',
                                        // objectFit: 'contain',
                                    }}
                                    ref={imageRef}
                                    className='product-page-main-img'

                                />
                            </div>


                            {/* <div className="fixed bottom-10 flex item-center mt-4">
                            <button className='text-lg bg-gray-400 px-4 py-1 shadow-lg rounded-lg' onClick={() => setQuantity(prev => Math.max(1, prev - 1))}>-</button>
                            <span className='text-lg bg-gray-50 px-4 pt-3 shadow-lg rounded-lg'>{quantity}</span>
                            <button className='text-lg bg-gray-400 px-4 py-1 shadow-lg rounded-lg' onClick={() => setQuantity(prev => prev + 1)}>+</button>
                            <button onClick={onClose} className="m-1 bg-blue-500 text-white p-2 shadow-lg rounded-lg">Add to Cart</button>
                        </div> */}

                        </div>

                        <div className='w-full h-full md:w-3/5 p-4 pb-5 m-0 mt-0 md:mt-20 mb-36 md:mb-36 bg-gray-100 bg-opacity-70 rounded-xl shadow-xl product-details-box'>

                            {/* Social Share Icons */}
                            <div className="social-share flex justify-center mt-0 space-x-4">
                                {/* <div className="flex justify-center mt-4 space-x-4"> */}
                                <a href={`https://www.facebook.com/sharer/sharer.php?u=${productUrlName}`} target="_blank" rel="noopener noreferrer" className='mr-2'>
                                    <IconContext.Provider value={{ size: "1.6em", color: "#1877F2" }}>
                                        <FaFacebook />
                                    </IconContext.Provider>
                                </a>

                                <a href={`https://social-plugins.line.me/lineit/share?url=${productUrlName}`} target="_blank" rel="noopener noreferrer" className='mr-2'>
                                    <IconContext.Provider value={{ size: "1.6em", color: "#00C300" }}>
                                        <FaLine />
                                    </IconContext.Provider>
                                </a>

                                <a href={`https://t.me/share/url?url=${productUrlName}`} target="_blank" rel="noopener noreferrer" className='mr-2'>
                                    <IconContext.Provider value={{ size: "1.6em", color: "#0088cc" }}>
                                        <FaTelegram />
                                    </IconContext.Provider>
                                </a>

                                <a href={`weixin://dl/officialaccounts?scene=108&need_open_webview=0&url=${productUrlName}`} target="_blank" rel="noopener noreferrer" className='mr-2'>
                                    <IconContext.Provider value={{ size: "1.6em", color: "#1AAD19" }}>
                                        <IoLogoWechat />
                                    </IconContext.Provider>
                                </a>
                                <a
                                    href={`https://twitter.com/intent/tweet?url=${productUrlName}}&text=${shareText}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Share on Twitter"
                                    className="text-blue-400"
                                >
                                    <FaTwitter size={28} />
                                </a>
                                <a
                                    href={`https://api.whatsapp.com/send?text=${shareText}%20${productUrlName}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Share on WhatsApp"
                                    className="text-green-500"
                                >
                                    <FaWhatsapp size={28} />
                                </a>
                            </div>

                            <p className="mt-2 text-sm text-blue-600 font-semibold break-all">
                                Category: {product?.cat_name}
                            </p>
                            <p className="mt-2 text-sm text-blue-600 font-normal break-all">
                                SKU: {product?.product_code}
                            </p>

                            <p className='mt-2 mb-4 text-lg font-seminbold'>{product?.product_desc}</p>

                            {product && <ProductDetails product={product} />} {/* Use your new component here */}

                        </div>


                    </div>

                    <div className="bottom_price_bar m-0 pt-4 pb-20 md:pb-10 w-screen flex justify-center bg-gray-200 rounded-t-3xl ">

                        {/* {isAuthenticated() && hasRole(['dealer']) && product?.id !== undefined && (

                                    <button className="m-1 bg-green-100 text-black p-2 shadow-lg rounded-lg">
                                        <GetMTPrice productId={product?.id} />
                                    </button>
                                )} */}
                        <div className='flex flex-row justify-start mr-2'>

                            {/* Conditional rendering for product link icon */}
                            {product?.product_link && (
                                <a
                                    href={product?.product_link}
                                    className='mr-2'
                                    target="_blank"
                                    rel='noreferrer'
                                    data-tooltip-id={`link${product?.id}`}
                                    data-tooltip-content={`Go to Product Details Link`}
                                >
                                    <IconContext.Provider value={{ size: "1.6em", color: "purple", className: "" }}>
                                        <div>
                                            <FaLink />
                                        </div>
                                    </IconContext.Provider>

                                    {/* <ReactTooltip
                                    id={`link${product?.id}`}
                                    place="left"
                                    style={{ backgroundColor: "yellow", color: "#000000" }}
                                /> */}
                                </a>
                            )}

                            {/* Conditional rendering for warranty icon */}
                            {product?.warranty && (
                                <IconContext.Provider value={{ size: "1.6em", color: "brown", className: "" }}>
                                    <div
                                        data-tooltip-id={`warranty${product?.id}`}
                                        data-tooltip-content={`${product?.warranty} warranty`}
                                    >
                                        <FaShieldHeart />
                                    </div>
                                </IconContext.Provider>
                            )}

                            {/* Tooltip for warranty icon */}
                            {product?.warranty && (
                                <ReactTooltip
                                    id={`warranty${product?.id}`}
                                    place="top"
                                    style={{ backgroundColor: "yellow", color: "#000000" }}
                                />
                            )}
                        </div>

                        {product?.MTPriceArray && product?.MTPriceArray.length > 0 && (
                            <div>
                                {/* <h3>MT Prices:</h3> */}
                                <ul className='flex flex-row'>
                                    {product?.MTPriceArray.map((item, index) => (
                                        <li key={index} className="mx-1 my-0 px-2 py-1 bg-green-600 text-xs md:text-xl text-white shadow-lg rounded-lg">
                                            <span className='my-0 py-0 text-xs text-wite font-semibold' style={{ fontSize: '1em' }}>{item.cat_name}: {displayPrice(Number(item.price))}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {product?.MTPrice !== undefined && product?.MTPrice > 0 && (
                            <button className="mx-1 my-0 px-2 py-1 bg-green-600 text-xs md:text-xl text-white  shadow-lg rounded-lg">
                                <span className='text-sm'>VIP </span>
                                <span className='font-bold'>{displayPrice(product?.MTPrice)}</span>
                            </button>
                        )}

                        <button className="mx-2 my-0 px-4 py-1  bg-black font-bold text-xs  md:text-xl text-white  shadow-lg rounded-lg">{displayPrice(product?.product_value ?? 0) || 'Product Price Not Available'}</button>

                        {userId > 0 && (
                            <div className="add2carft-div">
                                <button onClick={() => product && handleAddToCart(product, imageRef)} className="add2carft-btn">
                                    Add to Cart
                                </button>
                            </div>
                        )}


                        {/* Modifier Modal */}
                        {showModifierModal && selectedProduct && (
                            <ModifierModal
                                product={selectedProduct}
                                modifiers={modifiers}
                                onClose={handleCloseModifierModal}
                                onModifierSelection={handleModifierSelection}
                                onConfirm={handleConfirmModifiers}
                                selectedModifiers={selectedModifiers}
                            />
                        )}

                    </div>
                </div>

            </div>
        </>

    );

};

export default ProductPage;