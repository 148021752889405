import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useCart } from '../context/cartContext';
import { CartItem, Product } from '../utils/types';
import { FaCartPlus, FaTrash } from "react-icons/fa";
import { displayPrice } from '../utils/types';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
// import { motion, AnimatePresence } from 'framer-motion';

interface FloatingCartProps {
    cartRef: React.RefObject<HTMLDivElement>;
}

interface ProductWithImageRef extends Product {
    imageRef: React.RefObject<HTMLImageElement>;
}

const FloatingCart: React.FC<FloatingCartProps> = ({ cartRef }) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const userId = user.id;

    const { state, dispatch } = useCart();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const navigate = useNavigate();

    const [animationQueue, setAnimationQueue] = useState<{ product: ProductWithImageRef; imageRef: React.RefObject<HTMLImageElement> }[]>([]);

    const handleClearCart = () => {
        dispatch({ type: 'CLEAR_CART' });
    };

    const handleGoToCart = () => {
        navigate('/cart');
    };

    const handleRemoveFromCart = (item: CartItem) => {
        dispatch({ type: 'REMOVE_FROM_CART', payload: item });
    };

    const totalItemCount = state.items.reduce((total: number, item: CartItem) => total + item.quantity, 0);

    useEffect(() => {
        if (state.items.length > 0) {
            setIsExpanded(true);
            setIsAnimating(true);

            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            if (!isHovered) {
                timerRef.current = setTimeout(() => {
                    setIsExpanded(false);
                    setIsAnimating(false);
                }, 2000);
            }
        } else {
            setIsExpanded(false);
            setIsAnimating(false);
        }

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [state.items, isHovered]);

    const handleEdgeClick = () => {
        setIsExpanded(true);
        setIsAnimating(true);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        if (isExpanded) {
            timerRef.current = setTimeout(() => {
                setIsExpanded(false);
                setIsAnimating(false);
            }, 2000);
        }
    };

    const cartContainer = `
        fixed top-28 right-0
        w-full md:w-auto bg-transparent rounded-lg pl-10 p-0 z-50
        transition-transform duration-500
        ${isExpanded ? 'translate-x-0' : 'translate-x-[calc(100%-40px)] md:translate-x-[calc(100%-40px)]'}
        ${isAnimating ? '' : 'translate-x-[100%]'}
    `;

    const containerClasses = `
        bg-white border border-gray-300 shadow-lg rounded-lg rounded-tl-none pl-4 md:pl-12 p-4 z-50
    `;

    const edgeClasses = `
        absolute top-0 left-0 h-12 w-[40px] md:w-[40px] bg-green-300 bg-opacity-70 cursor-pointer rounded-l-lg flex items-center justify-center
    `;

    // Animation Logic
    const startCartAnimation = useCallback((product: ProductWithImageRef, imageRef: React.RefObject<HTMLImageElement>) => {

        console.log('FloatingCart: startCartAnimation called');
        console.log('FloatingCart: startCartAnimation imageRef:', imageRef);
        console.log('FloatingCart: startCartAnimation imageRef.current:', imageRef?.current);
        console.log('FloatingCart: startCartAnimation cartRef.current:', cartRef.current);

        if (!imageRef.current || !cartRef.current) {
            // console.error('FloatingCart: startCartAnimation: imageRef.current or cartRef.current is null or undefined!');

            return;
        }

        // console.log(imageRef);
        const imageRect = imageRef.current.getBoundingClientRect();
        const cartRect = cartRef.current.getBoundingClientRect();

        const clonedImage = imageRef.current.cloneNode(true) as HTMLImageElement;
        clonedImage.style.position = 'fixed';
        clonedImage.style.left = `${imageRect.left}px`;
        clonedImage.style.top = `${imageRect.top}px`;
        clonedImage.style.width = `${imageRect.width}px`;
        clonedImage.style.height = `${imageRect.height}px`;
        clonedImage.style.zIndex = '1000';
        clonedImage.style.transition = 'all 0.5s ease-in-out';
        clonedImage.style.pointerEvents = 'none'; // Ensure the cloned image doesn't interfere with clicks
        document.body.appendChild(clonedImage);

        // Calculate the center of the cart icon
        const cartCenterX = cartRect.left + cartRect.width / 2;
        const cartCenterY = cartRect.top + cartRect.height / 2;

        // Calculate the scale factor to shrink the image
        const scaleFactor = 0.2; // Adjust this value as needed

        // Calculate the final width and height of the image
        const finalWidth = imageRect.width * scaleFactor;
        const finalHeight = imageRect.height * scaleFactor;

        // Calculate the final position of the image
        const finalLeft = cartCenterX - finalWidth / 2;
        const finalTop = cartCenterY - finalHeight / 2;

        // Use requestAnimationFrame to ensure the transition is smooth
        requestAnimationFrame(() => {
            clonedImage.style.left = `${finalLeft}px`;
            clonedImage.style.top = `${finalTop}px`;
            clonedImage.style.width = `${finalWidth}px`;
            clonedImage.style.height = `${finalHeight}px`;
            clonedImage.style.opacity = '0'; // Fade out the image

            clonedImage.addEventListener('transitionend', () => {
                clonedImage.remove();
            }, { once: true });
        });
    }, [cartRef]);

    useEffect(() => {
        // console.log('FloatingCart: useEffect - animationQueue changed', animationQueue);


        if (animationQueue.length > 0) {

            const { product, imageRef } = animationQueue[0]; // Get both product and imageRef

            // console.log('FloatingCart: useEffect - product:', product);
            // console.log('FloatingCart: useEffect - imageRef:', imageRef);

            if (imageRef) {
                startCartAnimation(product, imageRef);
            }
            setAnimationQueue(prevQueue => prevQueue.slice(1));
        }
    }, [animationQueue, startCartAnimation]);

    // console.log('FloatingCart: state.item:', state.items);

    return (
        <div>
            {userId > 0 && (
                <div
                    className={cartContainer}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className={edgeClasses} onClick={handleEdgeClick} ref={cartRef}>
                        <FaCartPlus size={20} color="black" className='animate-pulse' />
                        {totalItemCount > 0 && (
                            <span className="absolute top-[-8px] right-[18px] bg-red-500 text-white text-xs font-bold rounded-full px-2 py-1">
                                {totalItemCount}
                            </span>
                        )}
                    </div>

                    <div className={containerClasses}>
                        <h3 className=" text-lg font-bold mb-2">Cart</h3>
                        {state.items.length === 0 ? (
                            <p>Your cart is empty</p>
                        ) : (
                            <div className='mx-1 px-2 overflow-y-auto max-h-96'>
                                <ol className=" divide-y divide-gray-200" style={{ counterReset: 'fc-counter' }}>
                                    {state.items.map((item: CartItem) => (
                                        <li key={item.cartItemId + uuidv4()} className="text-xs py-4 ">
                                            <div className=' before:mr-2 md:before:mr-4' style={{ counterIncrement: ' fc-counter' }}>
                                                <span className='md:w-64'>
                                                    <Link to={`/product/${item.id}`}>
                                                        {item.product_name}
                                                        <span className="text-xs md:text-xs text-gray-500 pl-2">sku: {item.product_code}</span>

                                                    </Link>
                                                    &nbsp;({displayPrice(Number(item.product_value))})
                                                </span>
                                                <span className='md:w-2'> &nbsp; </span>

                                                <span className='w-4 md:w-12'>{item.quantity}</span>
                                                <span className='w-2 md:w-2'> x </span>
                                                <span className='w-16 md:w-16'>{displayPrice(Number(item.price))}</span>
                                                <button onClick={() => handleRemoveFromCart(item)} className="ml-2 text-red-500">
                                                    <FaTrash />
                                                </button>
                                            </div>

                                            {item.modifiers.length > 0 && (
                                                <div className='modifiers-div'>
                                                    <ul className="list-disc list-inside ">
                                                        {item.modifiers.map((modifier) => (
                                                            <li key={item.cartItemId + modifier.id + uuidv4()} className="text-xs">
                                                                {modifier.product_name}
                                                                <span className="text-xs md:text-xs text-gray-500 pl-2">sku: {modifier.product_code}</span>

                                                                &nbsp;({displayPrice(modifier.product_value)})
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </li>
                                    ))}
                                </ol>
                            </div>
                        )}

                        {state.items.length > 0 && (
                            <button onClick={handleClearCart} className="text-xs mt-2 bg-red-500 text-white px-4 py-2 rounded">
                                Clear Cart
                            </button>
                        )}
                        <button onClick={handleGoToCart} className="text-xs mt-2 bg-blue-500 text-white px-4 py-2 rounded">
                            Cart
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FloatingCart;
