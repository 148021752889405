import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaShoppingCart, FaHistory, FaWhatsapp, FaBars, FaHome } from 'react-icons/fa'; // Import icons
import { appConfig } from '../utils/types';

// const Footer = ({ appConfig }: { appConfig: appConfig }) => {
const Footer = () => {
    const settings = JSON.parse(localStorage.getItem('settings') || '{}'); // Get settings from local storage
    const fqdn = 'https://' + window.location.hostname;  // Get the current host FQDN

    useEffect(() => {
        const currentHost = window.location.hostname;

        // URL of the external CSS
        const externalCssUrl = 'https://u8.my/excss/excss.css';

        // List of domains where you want to load the external CSS
        const productionDomains = ['price.ka.my', 'next.ka.my', 't1.u8.my'];
        const developmentDomains = ['localhost', '127.0.0.1'];

        // Check if we're in production and on one of the specified domains
        if (process.env.NODE_ENV === 'development' && developmentDomains.includes(currentHost)) {
            // Create a link element
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = externalCssUrl;
            link.type = 'text/css';

            // Append the link element to the head
            document.head.appendChild(link);

            // Cleanup: remove the link when the component is unmounted
            return () => {
                document.head.removeChild(link);
            };
        }

        if (process.env.NODE_ENV === 'production' && productionDomains.includes(currentHost)) {
            // Create a link element
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = externalCssUrl;
            link.type = 'text/css';

            // Append the link element to the head
            document.head.appendChild(link);

            // Cleanup: remove the link when the component is unmounted
            return () => {
                document.head.removeChild(link);
            };
        }
    }, []); // Empty dependency array means this runs once on mount

    return (
        <footer className="footer z-50 sticky bottom-0 w-full ">
            {/* Quick Menu */}
            <div className="bg-green-500 text-white flex justify-around items-center p-2 md:visible ">
                <Link to="/" className="flex flex-col items-center">
                    <FaHome size={18} />
                    <span className="text-xs">Home</span>
                </Link>
                <div className="relative">
                    <button
                        onClick={() => {
                            const menu = document.getElementById('nav-menu');
                            if (menu) {
                                menu.classList.toggle('hidden');
                                menu.classList.toggle('slide-in');
                            }
                        }}
                        className="flex flex-col items-center"
                    >
                        <FaBars size={18} />
                        <span className="text-xs">Menu</span>
                    </button>
                    <div
                        id="nav-menu"
                        className="hidden fixed top-0 right-0 h-full w-4/5 bg-white shadow-md p-4 slide-in-animation rounded-l-xl "
                        onMouseLeave={() => {
                            const menu = document.getElementById('nav-menu');
                            if (menu) {
                                menu.classList.add('slide-out-to-right');
                                menu.classList.remove('slide-in');
                            }
                        }}
                    >
                        <button
                            onClick={() => {
                                const menu = document.getElementById('nav-menu');
                                if (menu) {
                                    menu.classList.add('hidden');
                                    menu.classList.remove('slide-in');
                                }
                            }}
                            className="absolute top-4 right-4 text-red-700"
                        >
                            Close
                        </button>
                        <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 border border-gray-200 rounded-md">
                            <p className='font-bold'>Office Address</p>
                            <br />

                            <p>
                                {settings?.address && settings.address.trim() !== '' && (
                                    <>
                                        {settings.address}
                                        <br />
                                    </>
                                )}
                                {settings?.address_2 && settings.address_2.trim() !== '' && (
                                    <>
                                        {settings.address_2}
                                        <br />
                                    </>
                                )}
                                {settings?.address_3 && settings.address_3.trim() !== '' && (
                                    <>
                                        {settings.address_3}
                                        <br />
                                    </>
                                )}
                                {settings?.city && settings.city.trim() !== '' && (
                                    <>
                                        {settings.city}
                                        <br />
                                    </>
                                )}
                                {settings?.state && settings.state.trim() !== '' && (
                                    <>
                                        {settings.state}
                                        <br />
                                    </>
                                )}
                                {settings?.zipcode && settings.zipcode.trim() !== '' && (
                                    <>
                                        {settings.zipcode}
                                        <br />
                                    </>
                                )}
                                {settings?.country && settings.country.trim() !== '' && (
                                    <>
                                        {settings.country}
                                        <br />
                                    </>
                                )}
                                {settings?.phone && settings.phone.trim() !== '' && (
                                    <>
                                        <br />

                                        Tel : {settings.phone}
                                        <br />
                                    </>
                                )}
                            </p>
                        </div>

                        <a
                            href={`https://wa.me/${settings?.phone || '601112163808'}?text=Enquiry%20from%20${settings?.shortname || 'u8.my'}!`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block px-4 py-2 text-sm text-green-700 hover:bg-gray-100 animate-pulse border border-gray-200 rounded-md"
                        >
                            WhatsApp us
                        </a>
                    </div>
                </div>
                <Link to="/order-history" className="flex flex-col items-center">
                    <FaHistory size={18} />
                    <span className="text-xs">Orders</span>
                </Link>
                <Link to="/cart" className="flex flex-col items-center">
                    <FaShoppingCart size={18} />
                    <span className="text-xs">Cart</span>
                </Link>
                <a href={`https://wa.me/${settings?.phone || '601112163808'}?text=Enquiry%20from%20${settings?.shortname || 'u8.my'}!`} target="_blank" rel="noopener noreferrer" className="flex flex-col items-center">
                    <FaWhatsapp size={18} />
                    <span className="text-xs">WhatsApp</span>
                </a>
            </div>

            {/* Main Footer Content */}
            <div className="footer-copy-div">
                <p>&copy; {new Date().getFullYear()}
                    &nbsp;
                    {process.env.REACT_APP_COMPANY_NAME}
                    &nbsp;
                    @
                    &nbsp;
                    {fqdn}
                    &nbsp;
                    All Rights Reserved. Developed by Boyd Chung</p>
            </div>
        </footer>
    );
};

export default Footer;
