// src/pages/OrderHistory.tsx
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { fetchOrderHistory } from '../utils/api';
import { displayPrice, OrderSummary, Customer } from '../utils/types';
import useCancelOrder from '../hooks/useCancelOrder'; // Import the hook
import useDeleteOrder from '../hooks/useDeleteOrder'; // Import the hook
import { hasRole } from '../utils/auth';
import { CustomerContext, CustomerContextProps } from '../context/customerContext';

const OrderHistory: React.FC = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}'); // Get user ID from local storage
    // console.log('user >>>>>', user);
    const userId = user.id;
    const role = JSON.parse(localStorage.getItem('rolesPermissions') || '{}').roles; // Assuming you want the first role

    // console.log('role >>>>>', role);


    // const customer: Customer | null = JSON.parse(localStorage.getItem('customer') || 'null'); // Get customer data from local storage
    // const customerId = customer ? customer.id : 0;

    // const { customers } = React.useContext(CustomerContext) as CustomerContextProps;
    const { customers, refreshCustomers } = useContext(CustomerContext) as CustomerContextProps;

    // console.log('ohp ln 25 customers >>>>>', customers);

    const [orders, setOrders] = useState<OrderSummary[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [status, setStatus] = useState<string>(''); // Default status
    const [limit, setLimit] = useState<number>(10); // Default limit
    const { handleCancelOrder, isCancelling } = useCancelOrder(); // Use the hook
    const { handleDeleteOrder, deleteError } = useDeleteOrder(); // Use the hook
    const [cancellingOrderId, setCancellingOrderId] = useState<number | null>(null);
    const [deletingOrderId, setDeletingOrderId] = useState<number | null>(null);

    // const customer: Customer = JSON.parse(localStorage.getItem('customer') || '[]'); // Get user ID from local storage

    useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await fetchOrderHistory(userId, status, currentPage, limit, role);
                // console.log('ohp ln 37 data >>>>>', data);

                setOrders(data.data);
                setTotalPages(data.last_page);
            } catch (err: any) {
                if (err.response && err.response.status === 401) {
                    setError('You must log in to view your order history.');
                } else {
                    setError(err.message || 'Failed to fetch order history');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, [currentPage, status, limit]);

    const handleRefreshCustomers = () => {
        refreshCustomers(); // Trigger a refresh of the customer data
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleStatusChange = (newStatus: string) => {
        setStatus(newStatus);
        setCurrentPage(1); // Reset to first page when status changes
    };

    const handleLimitChange = (newLimit: number) => {
        setLimit(newLimit);
        setCurrentPage(1); // Reset to first page when limit changes
    };

    // Function to update the order status in the local state
    const updateOrderStatus = (updatedOrder: OrderSummary) => {
        setOrders(prevOrders =>
            prevOrders.map(order =>
                order.id === updatedOrder.id ? { ...order, status: updatedOrder.status } : order
            )
        );
    };

    // Function to remove the deleted order from the local state
    const removeDeletedOrder = (orderId: number) => {
        setOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
    };

    if (loading) {
        return <div className="container mx-auto p-4">Loading order history...</div>;
    }

    if (error) {
        return <div className="container mx-auto p-4 text-red-500">Error: {error}</div>;
    }

    return (
        <div className="container mx-auto p-4">

            {hasRole(['super_admin', 'staff']) && (
                <button className='fixed top-2 left-0  md:relative z-10 text-white bg-green-600 mr-5 px-4 py-1 rounded-lg mb-4'>
                    <Link to="/" className="block md:inline-block mr-4 text-xs">
                        {/* I am a Super Admin or Staff */}
                        {/* , only admin & staff can see and use */}
                        Management Screen
                    </Link>
                </button>
            )}

            <h1 className="text-2xl font-bold mb-4">Order History</h1>

            {/* Status Filter */}
            <div className="mb-4">
                <label htmlFor="statusFilter" className="mr-2">Filter by Status:</label>
                <select
                    id="statusFilter"
                    value={status}
                    onChange={(e) => handleStatusChange(e.target.value)}
                    className="border border-gray-300 rounded px-2 py-1"
                >
                    <option value="">Show All</option>
                    <option value="pending">Pending</option>
                    <option value="processing">Processing</option>
                    <option value="completed">Completed</option>
                    <option value="cancel">Cancelled</option>
                </select>
            </div>

            {/* Limit Filter */}
            <div className="mb-4">
                <label htmlFor="limitFilter" className="mr-2">Orders per page:</label>
                <select
                    id="limitFilter"
                    value={limit}
                    onChange={(e) => handleLimitChange(parseInt(e.target.value))}
                    className="border border-gray-300 rounded px-2 py-1"
                >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>

            {orders.length === 0 ? (
                <p>No orders found.</p>
            ) : (
                <div className="space-y-4"> {/* Container for order items */}
                    {orders.map((order) => (
                        <div key={order.id} className="border border-gray-200 rounded p-4"> {/* Order item container */}
                            <div className="md:flex md:justify-between"> {/* Flex container for row layout on medium screens */}
                                <div className="mb-2 md:mb-0"> {/* Column for Order ID and Customer */}
                                    <p className="font-semibold">Order ID: {order.id}</p>
                                    <p>Customer: &nbsp;{order.customer_id}&nbsp;:
                                        &nbsp;{customers.find(c => c.id === order.customer_id)?.name || 'Loading . . . please refresh you browser.'}
                                    </p>
                                </div>
                                <div className="mb-2 md:mb-0"> {/* Column for Date and Status */}
                                    <p>Date: {new Date(order.created_at).toLocaleString()}</p>
                                    <p className={`font-semibold 
                                        ${order.status ===
                                            'pending' ? 'text-blue-500'
                                            : order.status === 'processing' ? 'text-green-500'
                                                : order.status === 'cancel' ? 'text-red-500'
                                                    : order.status === 'completed' ? 'text-brown-500' : 'text-black'
                                        }`}>
                                        Status: {order.status.toUpperCase()}
                                    </p>
                                </div>
                                <div className="mb-2 md:mb-0"> {/* Column for Total */}
                                    <p className='font-semibold text-green-600'>Total: {displayPrice(Number(order.balance))}</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap gap-2 mt-2"> {/* Flex container for buttons */}
                                <Link to={`/order/${order.id}`} className="bg-blue-500 hover:bg-blue-700 text-xs text-white font-bold py-1 px-2 rounded  hover:underline">
                                    View Details
                                </Link>

                                {((order.status !== 'cancel' && order.user_id === userId) || (order.status !== 'cancel' && hasRole(['super_admin', 'staff']))) && (
                                    <button
                                        onClick={() => handleCancelOrder(order.id, updateOrderStatus)} // Pass the callback
                                        className="bg-red-500 hover:bg-red-700 text-xs  text-white font-bold py-1 px-2 rounded"
                                        disabled={cancellingOrderId !== null} // Disable while cancelling
                                    >
                                        {cancellingOrderId === order.id ? 'Cancelling order ' + order.id + '...' : 'Cancel order ' + order.id}
                                    </button>
                                )}

                                {order.id !== null && order.status === 'cancel' && (
                                    <button
                                        onClick={async () => {
                                            setDeletingOrderId(order.id);
                                            await handleDeleteOrder(order.id, removeDeletedOrder);
                                            setDeletingOrderId(null);
                                        }} // Pass the callback
                                        className="bg-orange-600 hover:bg-orange-800 text-xs text-white font-bold py-1 px-2 rounded"
                                        disabled={deletingOrderId !== null} // Disable while deleting
                                    >
                                        {deletingOrderId === order.id ? 'Deleting order ' + order.id + '...' : 'Delete order ' + order.id}
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Pagination */}
            {totalPages > 1 && (
                <div className="mt-4 flex justify-center">
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                            className={`mx-1 px-3 py-1 rounded ${currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200'
                                }`}
                        >
                            {page}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default OrderHistory;
