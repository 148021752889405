// src/hooks/useDeleteOrder.ts
import { useState } from 'react';
import { deleteOrder } from '../utils/api';
import { toast } from 'react-toastify'; // Import toast

interface UseDeleteOrderResult {
    handleDeleteOrder: (orderId: number, removeOrder?: (orderId: number) => void) => Promise<void>;
    isDeleting: boolean;
    deleteError: string | null;
}

const useDeleteOrder = (): UseDeleteOrderResult => {
    const [isDeleting, setIsDeleteling] = useState<boolean>(false);
    const [deleteError, setDeleteError] = useState<string | null>(null);

    const handleDeleteOrder = async (orderId: number, removeOrder?: (orderId: number) => void) => {
        // toast.info("Are you sure you want to delete this order '" + orderId + "'?");
        const confirmDelete = window.confirm("Are you sure you want to delete this order '" + orderId + "'?");
        if (!confirmDelete) {
            return;
        }

        setIsDeleteling(true);
        setDeleteError(null);

        try {
            const response = await deleteOrder(orderId);
            // console.log('useDeleteOrder ln 21 response > ', response);
            if (removeOrder) {
                removeOrder(orderId);
            }
            // alert('Order deleteled successfully!');
            toast.success('Order ' + orderId + ' deleteled successfully!', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
        } catch (err: any) {
            setDeleteError(err.message || 'Failed to delete order');
            // alert('Failed to delete order. Please try again.');
            toast.error('Failed to delete order ' + orderId + '! Please try again.');

        } finally {
            setIsDeleteling(false);
        }
    };

    return { handleDeleteOrder, isDeleting, deleteError };
};

export default useDeleteOrder;
