import React from 'react';
import { useCart } from '../context/cartContext';
import { CartItem, displayPrice, OrderItem, Customer } from '../utils/types';
import { createOrder } from '../utils/api'; // Import the createOrder function
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { v4 as uuidv4 } from 'uuid'; // Import uuid
import { toast } from 'react-toastify'; // Import toast

const Cart: React.FC = () => {
    const { state, dispatch } = useCart();
    const { items } = state; // Access items directly from state
    const navigate = useNavigate(); // Initialize useNavigate
    const user = JSON.parse(localStorage.getItem('user') || '{}'); // Get user ID from local storage
    // console.log('user >>>>>', user);
    const userId = user.id;
    const name = user.name;
    const customer: Customer | null = JSON.parse(localStorage.getItem('customer') || 'null'); // Get customer data from local storage
    const customerId = customer ? customer.id : 0;

    const handleRemoveFromCart = (cartItemId: string) => {
        dispatch({
            type: 'REMOVE_FROM_CART',
            payload: { cartItemId }, // Use cartItemId here
        });
    };

    // Calculate the total price of items in the cart
    const calculateTotal = () => {
        return items.reduce((total: number, item: CartItem) => total + item.price * item.quantity, 0);
    };

    const handleCheckout = async () => {
        const orderItems: OrderItem[] = items.map((item: CartItem): OrderItem => {
            // Calculate the total price for each item including modifiers
            const modifierTotalPrice = item.modifiers.reduce((sum, modifier) => sum + Number(modifier.product_value), 0);
            const totalPrice = Number(item.price) + Number(modifierTotalPrice);

            return {
                order_id: 0,
                customer_id: customerId,
                category_id: 0,
                product_category: item.product_category,
                product_id: item.id,
                product_code: item.product_code,

                product_name: item.product_name,
                quantity: item.quantity,
                product_value: item.product_value,
                price: item.price, // Use the total price including modifiers
                total_price: (item.price * item.quantity),
                payment_desc: item.product_code,
                payment_for: item.product_name,
                qty: item.quantity,
                unit_price: item.price,
                balance: (item.price * item.quantity),
                tranx_details: '',
                modifiers: item.modifiers,
                checkout: false,
            };
        });

        const orderPayload = {
            customerId: customerId,
            orderType: 'order',
            tableId: '1',
            items: orderItems, // Filter items with checkout = false
            status: 'pending',
            amountToPay: calculateTotal(),
            userId: userId,
            table: [],
            customer_name: name,
        };

        try {
            const token = localStorage.getItem('token'); // Get the stored token
            // console.log('token >>>>>', token);

            const response = await createOrder(orderPayload);
            console.log('Order created:', response);
            // Handle successful order creation (e.g., clear the cart, show a success message)
            dispatch({ type: 'CLEAR_CART' });
            // alert('Order created successfully!');
            toast.success('Order created successfully!', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            navigate(`/order/${response.data.id}`); // Navigate to the order details page

        } catch (error) {
            console.error('Error creating order:', error);
            // Handle order creation error (e.g., show an error message)
            // alert('Error creating order. Please try again.');
            toast.error('Error creating order! Please try again.');

        }
    };

    // console.log(' cartp >>>>>', state.items);

    // console.log(' cartp ln 89 customer_id > ', customer ? customer.id : 'No customer');

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Cart</h1>
            {items.length === 0 ? (
                <p className="text-gray-600">Your cart is empty</p>
            ) : (
                <ol className="list-decimal list-inside divide-y divide-gray-200" style={{ counterReset: 'my-counter' }}> {/* Add counterReset */}
                    {items.map((item: CartItem, index: number) => (
                        <li key={item.cartItemId + uuidv4()} className="py-4 flex items-center justify-between before:content-[counter(my-counter)] before:mr-4" style={{ counterIncrement: 'my-counter' }}> {/* Add counterIncrement */}
                            <div className="flex-grow">
                                <p className="font-medium">
                                    {item.product_name}
                                    <span className="text-xs md:text-xs text-gray-500 pl-2">sku: {item.product_code}</span>

                                    &nbsp;({displayPrice(Number(item.product_value))})
                                </p>
                                {item.modifiers.length > 0 && (
                                    <div className='modifiers-div'>
                                        <ul className="list-disc list-inside ">
                                            {item.modifiers.map((modifier) => (
                                                <li key={item.cartItemId + modifier.id + uuidv4()} className="text-xs">
                                                    {modifier.product_name}
                                                    <span className="text-xs md:text-xs text-gray-500 pl-2">sku: {modifier.product_code}</span>

                                                    &nbsp;({displayPrice(modifier.product_value)})
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                <p className="text-sm text-gray-500">
                                    {displayPrice(item.price)} x {item.quantity} = {displayPrice(item.price * item.quantity)}
                                </p>
                            </div>
                            <div className="ml-4">
                                <button
                                    onClick={() => handleRemoveFromCart(item.cartItemId)} // Use cartItemId here
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Remove
                                </button>
                            </div>
                        </li>
                    ))}
                </ol>
            )}
            {items.length > 0 && (
                <div className="mt-4">
                    <p className="text-lg font-semibold">
                        Total: {displayPrice(calculateTotal())}
                    </p>
                    <button
                        onClick={handleCheckout}
                        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Checkout
                    </button>
                </div>
            )}
        </div>
    );
};

export default Cart;
