// src/hooks/useCancelOrder.ts
import { useState } from 'react';
import { cancelOrder } from '../utils/api';
import { OrderSummary } from '../utils/types';
import { toast } from 'react-toastify'; // Import toast

interface UseCancelOrderResult {
    handleCancelOrder: (orderId: number, updateOrders?: (updatedOrder: OrderSummary) => void) => Promise<void>;
    isCancelling: boolean;
    cancelError: string | null;
}

const useCancelOrder = (): UseCancelOrderResult => {
    const [isCancelling, setIsCancelling] = useState<boolean>(false);
    const [cancelError, setCancelError] = useState<string | null>(null);

    const handleCancelOrder = async (orderId: number, updateOrders?: (updatedOrder: OrderSummary) => void) => {
        const confirmCancel = window.confirm("Are you sure you want to cancel this order?");
        if (!confirmCancel) {
            return;
        }

        setIsCancelling(true);
        setCancelError(null);

        try {
            const response = await cancelOrder(orderId);
            // console.log('useCancelOrder ln 21 response > ', response);
            if (updateOrders) {
                updateOrders({ id: orderId, status: 'cancel', balance: 0, customer_id: 0, created_at: '', items: [], order_type: '', receivable: '', received_amount: '', rounding_value: '', rounded_receivable: '', service_charges: '', total_amount: '', total_taxes: '', updated_at: '', user_id: 0, vendor_id: 0, amount_to_pay: '', change: '', totalAmount: 0, totalTax: 0, totalWithTax: 0, roundingDifference: 0, table_id: null });
            }
            // alert('Order cancelled successfully!');
            toast.success('Order ' + orderId + ' cancelled successfully!', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } catch (err: any) {
            setCancelError(err.message || 'Failed to cancel order');
            alert('Failed to cancel order. Please try again.');
            toast.error('Failed to cancel order ' + orderId + '! Please try again.');

        } finally {
            setIsCancelling(false);
        }
    };

    return { handleCancelOrder, isCancelling, cancelError };
};

export default useCancelOrder;
