// src/components/ModifierModal.tsx
import React, { useState, useEffect } from 'react';
import { Modifier, Product, displayPrice, Category } from '../utils/types';

interface ModifierModalProps {
    product: Product;
    modifiers: Modifier[];
    onClose: () => void;
    onModifierSelection: (modifier: Modifier) => void;
    onConfirm: () => void;
    selectedModifiers: Modifier[];
}

const ModifierModal: React.FC<ModifierModalProps> = ({ product, modifiers, onClose, onModifierSelection, onConfirm, selectedModifiers }) => {
    const [selectedModifiersByCategory, setSelectedModifiersByCategory] = useState<Record<string, Modifier | null>>({});

    useEffect(() => {
        // Initialize selected modifiers by category
        const initialSelected = selectedModifiers.reduce((acc: Record<string, Modifier>, modifier) => {
            const category = modifier.product_addon_cat[0];
            acc[category] = modifier;
            return acc;
        }, {});
        setSelectedModifiersByCategory(initialSelected);
    }, [selectedModifiers]);

    const groupModifiersByCategory = (modifiers: Modifier[]) => {
        return modifiers.reduce((groups: Record<string, Modifier[]>, modifier) => {
            const category = modifier.product_addon_cat[0];
            if (!groups[category]) {
                groups[category] = [];
            }
            groups[category].push(modifier);
            return groups;
        }, {});
    };

    const handleModifierClick = (modifier: Modifier) => {
        const category = modifier.product_addon_cat[0];

        setSelectedModifiersByCategory((prev) => {
            const updated = { ...prev };

            if (updated[category]?.id === modifier.id) {
                updated[category] = null; // Deselect if already selected
            } else {
                updated[category] = modifier; // Select new modifier
            }

            return updated;
        });

        onModifierSelection(modifier);
    };

    const groupedModifiers = groupModifiersByCategory(modifiers);

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg w-full md:w-4/6">
                <h2 className="text-sm md:text-xl font-bold mb-4">
                    Choose Modifiers for <span className="text-blue-500">{product.product_name}</span>
                    <span className="text-gray-500"> ({displayPrice(product.MTPrice)})</span>
                </h2>

                <div className="overflow-y-auto max-h-96">
                    {Object.entries(groupedModifiers).map(([category, categoryModifiers]) => (
                        <div key={category} className="mb-4">
                            <div className="col-span-2 my-2 text-xs md:text-sm font-semibold text-gray-500">
                                {JSON.parse(localStorage.getItem('categories') || '[]').find((cat: Category) => cat.id === Number(category))?.cat_name || `Category ${category}`}
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                {categoryModifiers.map((modifier) => {
                                    const isSelected = selectedModifiersByCategory[category]?.id === modifier.id;

                                    return (
                                        <div
                                            key={modifier.id}
                                            className={`
                                                h-auto text-xs md:text-sm border p-2 rounded-md cursor-pointer
                                                ${isSelected ? 'text-white bg-indigo-300' : ''}`}
                                            onClick={() => handleModifierClick(modifier)}
                                        >
                                            <p className="text-xs md:text-sm font-semibold">
                                                {modifier.product_name}
                                                <br />
                                                <span className="text-xs md:text-xs text-gray-500">sku: {modifier.product_code}</span>
                                            </p>
                                            <p className="text-sm md:text-sm text-gray-900">
                                                {typeof modifier.product_value === 'number' ? displayPrice(modifier.product_value) : modifier.product_value}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="mt-6 flex justify-end">
                    <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2" onClick={onClose}>
                        Cancel
                    </button>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={onConfirm}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModifierModal;
