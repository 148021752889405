import React, { useRef } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import Logout from './pages/Logout';
import Register from './pages/Register';
import Header from './components/Header';
import Footer from './components/Footer';
import CsvUploader from './components/CSVUploader';
import ProductPage from './pages/ProductPage';
import { AuthProvider } from './context/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import { CartProvider, useCart } from './context/cartContext';
import { CustomerProvider } from './context/customerContext';

import Cart from './pages/Cart';
import FloatingCart from './components/FloatingCart';
import OrderDetails from './pages/OrderDetails'; // Import the new component
import OrderHistory from './pages/OrderHistory'; // Import the new component
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS
import { ConfigProvider } from './context/ConfigContext';
import { useEffect } from 'react';
import { appSettings } from './utils/api';



const user = JSON.parse(localStorage.getItem('user') || '{}'); // Get user ID from local storage
// console.log('user >>>>>', user);
const userId = user.id;


// New component to conditionally render FloatingCart
const ConditionalFloatingCart = ({ cartRef }: { cartRef: React.RefObject<HTMLDivElement> }) => {
  const { loading } = useCart();
  return loading ? null : <FloatingCart cartRef={cartRef} />; // Pass cartRef here
};


const App = () => {

  const cartRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const fetchSettings = async () => {
  //     try {
  //       const settings = await appSettings();
  //       // console.log('App.tsx ln 50 > Settings:', settings);
  //     } catch (error) {
  //       console.error('Failed to fetch app settings:', error);
  //     }
  //   };

  //   fetchSettings();
  // }, []);

  return (
    <ConfigProvider>

      <HelmetProvider>
        <AuthProvider>
          <CartProvider>
            <CustomerProvider>
              <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true, }}>
                <div className="flex flex-col min-h-screen ">
                  <HeaderWrapper />
                  <main className="flex-grow">
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/product/:productId/:productName?" element={<ProductPage />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/forgot-password" element={<ForgotPassword />} />
                      <Route path="/reset-password/:token" element={<PasswordReset />} />
                      <Route path="/logout" element={<Logout />} />
                      <Route path="/csv-uploader" element={<CsvUploader />} />
                      <Route path="/cart" element={<Cart />} />
                      <Route path="/order-history" element={<OrderHistory />} />

                      <Route path="/order/:orderId" element={<OrderDetails />} />

                    </Routes>
                  </main>
                  {/* {userId > 0 && */}
                  <FloatingCart cartRef={cartRef} />
                  {/* } */}
                  <Footer />

                  <ToastContainer
                    position="top-center" // You can change the position
                    autoClose={5000} // Time in milliseconds to auto-close
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />

                </div>
              </BrowserRouter>
            </CustomerProvider>
          </CartProvider>
        </AuthProvider>
      </HelmetProvider>
    </ConfigProvider>
  );
};

const HeaderWrapper = () => {
  const location = useLocation();
  const shouldHideHeader = location.pathname === '/';
  return shouldHideHeader ? null : <Header />;
};


export default App;
