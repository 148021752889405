import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchAllCustomer } from '../utils/api';
import { Customer } from '../utils/types';


export interface CustomerContextProps {
    customers: Customer[];
    loading: boolean;
    error: string | null;
    refreshCustomers: () => void; // Add refresh function to the context

}

export const CustomerContext = createContext<CustomerContextProps | undefined>(undefined);

interface CustomerProviderProps {
    children: React.ReactNode;
}

export const CustomerProvider: React.FC<CustomerProviderProps> = ({ children }) => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // useEffect(() => {
    //     const fetchCustomers = async () => {

    //         const token = localStorage.getItem('token');

    //         if (!token) {
    //             setLoading(false);
    //             return;
    //         }
            
    //         try {
    //             const customers = await fetchAllCustomer();
    //             // console.log(customers);
    //             setCustomers(customers);
    //         } catch (err: any) {
    //             setError(err.message || 'Failed to fetch customers');
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchCustomers();
    // }, []);


    const fetchCustomers = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setLoading(false);
                return;
            }
            const customers = await fetchAllCustomer();
            setCustomers(customers);
        } catch (err: any) {
            setError(err.message || 'Failed to fetch customers');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, []);
    
    const refreshCustomers = () => {
        fetchCustomers(); // Trigger a refresh of the customer data
    };

    return (
        <CustomerContext.Provider value={{ customers, loading, error, refreshCustomers }}>
            {children}
        </CustomerContext.Provider>
    );
};

export const useCustomerContext = () => {
    const context = useContext(CustomerContext);
    if (!context) {
        throw new Error('useCustomerContext must be used within a CustomerProvider');
    }
    return context;
};

