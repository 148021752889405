//my-web-app/src/utils/api.ts

// utils/api.ts
// import { useEffect, useState } from 'react';
import axios from 'axios';
import { Brand, Category, Product, appConfig } from './types';
import { setAuthToken, removeAuthToken, hasRole, isAuthenticated } from './auth';
// import { getConfig } from '../config/configLoader';



// Accessing the API URL from environment variables

// Type-safe access
const API_URL = window.APP_CONFIG?.API_URL || process.env.REACT_APP_API_URL;
const DOMAIN_URL = window.APP_CONFIG?.DOMAIN_URL || process.env.REACT_APP_DOMAIN_URL;

// const API_URL = process.env.REACT_APP_API_URL;
// const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
// console.log(API_URL);


// Create an axios instance with the base URL
export const api = axios.create({
    baseURL: API_URL,
});


// Add a response interceptor
api.interceptors.response.use(
    (response) => response, // Pass through successful responses
    (error) => {
        // Check for a 403 error
        if (error.response?.status === 403) {
            // Clear local storage and redirect to login
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = "/login"; // Force navigation
        } else if (error.response?.data?.message === "Token expired") {
            // Handle token expiration separately if needed
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = "/login"; // Force navigation
        }

        console.error('API Error:', error);
        return Promise.reject(error);
    }
);




export const checkSession = async () => {

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    // console.log('App > useEffect > user.remember_token', user.remember_token);
    // console.log('App > useEffect > user.session_id', user.session_id);
    const sessionId = user.session_id; // Retrieve the session_id from local storage
    const token = user.remember_token;
    // console.table('user', user);


    try {
        // const response = await api.get('/check-session?session_id=' + sessionId, {
        //     headers: {
        //         'X-Session-ID': sessionId,
        //     },

        if (isAuthenticated() && hasRole(['super_admin', 'staff'])) {

            console.log('No need to checkSession');

        } else {
            const response = await api.get('/check-session', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: {
                    session_id: sessionId,
                },
            });

            // console.log('checkSession response', response);
            // console.log('csd');
            console.log(hasRole(['super_admin', 'staff']), 'CS :', new Date().toLocaleString());

        }

    } catch (error) {
        console.error('Error checking session:', error);
    }
};

//my-web-app/src/utils/api.ts
export const appSettings = async (): Promise<appConfig> => {
    // api = await initializeApiClient();

    if (!api) {
        throw new Error('API client is not initialized. Please ensure it is initialized before calling appSettings.');
    }

    const response = await api.get('/settings');
    const settings = response.data;
    localStorage.setItem('settings', JSON.stringify(settings));
    
    // console.log('settings response > ', settings);

    return settings;
};

// Register function for new users
// my-web-app/src/utils/api.ts

export const register = async (
    name: string,
    email: string,
    password: string,
    password_confirmation: string,
    phone: string = '', // Optional phone field
    customUrl: string, // Additional parameter
    companyName: string, // Additional parameter
) => {
    try {
        const response = await api.post('/register', {
            name,
            email,
            password,
            password_confirmation,
            phone, // Include phone in the payload
            customUrl: DOMAIN_URL, // Additional parameter
            companyName: 'KA Price' // Additional parameter
        });

        const token = response.data.access_token;

        if (token) {
            setAuthToken(token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
        }

        return response;
    } catch (error) {
        console.error("Registration failed:", error);
        throw error;
    }
};


// Existing login function, modified to store user data in localStorage
export const login = async (email: string, password: string) => {
    const response = await api.post('/login', { email, password });
    const token = response.data.access_token;

    if (token) {
        setAuthToken(token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('customer', JSON.stringify(response.data.customer_id));
    }

    console.log('login response > ', response);

    return response;
};

// utils/api.ts

export const sendResetLink = async (
    email: string,
    customUrl: string, // Additional parameter
    companyName: string, // Additional parameter
) => {
    const response = await api.post('/forgot-password', {
        email,
        customUrl, // Adjust as needed
        companyName, // Adjust as needed
    });
    return response;
};

export const resetPassword = async (email: string, password: string, confirmPassword: string, token: string) => {
    const response = await api.post('/reset-password', {
        email,
        password,
        password_confirmation: confirmPassword,
        token,
    });
    return response;
};


// Function to fetch user roles and permissions
export const fetchRolesPermissions = async () => {
    const token = localStorage.getItem('token'); // Get the stored token
    if (!token) throw new Error('No authentication token found');

    const response = await api.get('/roles-permissions', {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
        },
    });
    return response.data; // Ensure this matches the structure of your roles and permissions data
};

// Fetch categories using the axios instance
export const fetchCategories = async (): Promise<Category[]> => {
    const response = await api.get('/categories'); // Use the api instance here
    // console.log('Category > ', response.data);
    localStorage.setItem('categories', JSON.stringify(response.data)); // Store categories in local storage
    return response.data; // Ensure this matches the structure of your Category type
};

// /src/utils/api.ts


export const fetchBrands = async (): Promise<Brand[]> => {
    const response = await api.get('/brands');
    return response.data;
};


// Fetch products with pagination and sorting options
export const fetchProducts = async (
    currentPage: number,
    search: string,
    selectedCategory: number | null,
    selectedBrand: number | null,
    perPage: number,
    sort: string
) => {
    let url = `/products2?page=${currentPage}&perPage=${perPage}&search=${search}&sort=${sort}`;

    if (selectedCategory) {
        url += `&category_id=${selectedCategory}`;
    }
    if (selectedBrand) {
        url += `&brand_id=${selectedBrand}`;
    }

    // console.log(url);

    // Get the authentication token if the user is logged in
    const token = localStorage.getItem('token'); // Get the stored token

    // Create a config object to include the token in the headers
    const config = token
        ? {
            headers: {
                Authorization: `Bearer ${token}`, // Send the token in the Authorization header
            },

        }
        : {}; // If no token, don't send any extra headers

    const response = await api.get(url, config); // Use the api instance here
    // console.log(url, config);
    // console.log('fetchProducts in api.ts >>', response);
    // Calculate total_pages using 'total' and 'perPage'
    const totalPages = Math.ceil(response.data.total / perPage);
    const totalProdcts = response.data.total;

    // Extract the products
    const products = response.data.data.map((product: any) => ({
        ...product,
        MTPrice: product.MTPrice || null, // Add MTPrice if available, or default to null
    }));

    return {
        // products: response.data.data, // Assuming 'data' contains the array of products
        products, // Assuming 'data' contains the array of products
        total_pages: totalPages,      // Calculating the total number of pages
        total_products: totalProdcts,      // Calculating the total number of products
    };
};

// Function to fetch a product by its ID
export const fetchProductById = async (id: number): Promise<Product> => {

    // Get the authentication token if the user is logged in
    const token = localStorage.getItem('token'); // Get the stored token

    // Create a config object to include the token in the headers
    const config = token
        ? {
            headers: {
                Authorization: `Bearer ${token}`, // Send the token in the Authorization header
            },

        }
        : {}; // If no token, don't send any extra headers

    // const response = await api.get(url, config); // Use the api instance here
    const response = await api.get(`/product/${id}`, config); // Adjust the endpoint as necessary
    // console.log('api.ts > ', id, response);
    return response.data; // Ensure this matches the structure of your Product type
};

// Function to fetch mt_price for the logged-in dealer
// src/utils/api.ts

export const fetchMtPrice = async (productId: number) => {
    const token = localStorage.getItem('token'); // Get the stored token
    const userId = JSON.parse(localStorage.getItem('user') || '{}').id; // Get user ID from local storage
    const role = JSON.parse(localStorage.getItem('rolesPermissions') || '{}').roles; // Assuming you want the first role

    if (!token) throw new Error('No authentication token found');

    const response = await api.get('/getmtprice', {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
        },
        params: {
            user_id: userId,
            role: role,
            token: token,
            product_id: productId, // Pass the product ID as a query parameter
        },
    });
    // console.log(response);

    return response.data; // Return the mt_price data
};

// src/utils/api.ts
export const fetchMtPrices = async (productIds: number[]) => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No authentication token found');

    const response = await api.get('/getmtprices', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            product_ids: productIds.join(','), // Send multiple IDs as a comma-separated string
        },
    });

    return response.data; // Adjust based on your API response structure
};

export const fetchAllMtPrices = async (productIds: number[]) => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No authentication token found');

    const response = await api.get('/allmtprices', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            product_ids: productIds.join(','), // Send multiple IDs as a comma-separated string
        },
    });

    return response.data; // Adjust based on your API response structure
};

export const uploadCsv = async (file: File) => {
    // const userId = JSON.parse(localStorage.getItem('user') || '{}').id; // Get user ID from local storage
    const role = JSON.parse(localStorage.getItem('rolesPermissions') || '{}').roles; // Assuming you want the first role
    // console.log('roles' ,role);
    if (role !== 'super_admin' && role !== 'staff') {
        throw new Error('You do not have permission to upload CSV files.');
    }

    const formData = new FormData();
    formData.append('csv_file', file);

    const response = await axios.post('https://t1.u8.my/api/import-csv', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};


export const fetchOrderDetails = async (orderId: number) => {

    const token = localStorage.getItem('token'); // Get the stored token


    const response = await api.get(`orders/${orderId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    // console.log('fetchOrderDetails in api ln205:', response);

    // Map the response data to include modifiers
    const orderData = {
        ...response.data,
        items: response.data.items.map((item: any) => ({
            ...item,
            modifiers: item.modifiers || [], // Ensure modifiers is an array
        })),
    };

    // console.log('fetchOrderDetails in api ln 405:', orderData);

    return orderData;
};


export const getOrderProcessLog = async (orderId: number) => {

    const token = localStorage.getItem('token'); // Get the stored token

    const response = await api.get(`/orders/${orderId}/process-log`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });


    // console.log('getOrderProcessLog:', response);

    return response.data; // Adjust based on the API response structure
};


export const fetchOrderHistory = async (userId: number, status: string, page: number = 1, limit: number = 10, role: string) => {

    const token = localStorage.getItem('token'); // Get the stored token
    // console.log('fetchOrderHistory:', token,  status, page, limit);
    // {hasRole(['super_admin', 'staff']) && (

    const response = await api.get(`/orders/history`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            user_id: userId, // Add userId to the request
            status,
            page,
            limit,
            role,
        },
    });

    // console.log('fetchOrderHistory:', response, userData, token);

    // Check if the response data is empty
    // if (!response.data || response.data.length === 0) {
    //     return 0;
    // }

    return response.data; // Adjust based on the API response structure
};


export const createOrder = async (payload: any) => {
    const token = localStorage.getItem('token'); // Get the stored token
    // const userId = JSON.parse(localStorage.getItem('user') || '{}').id; // Get user ID from local storage
    // const role = JSON.parse(localStorage.getItem('rolesPermissions') || '{}').roles; // Assuming you want the first role

    if (!token) throw new Error('No authentication token found');

    // const response = await api.get('/getmtprice', {


    const url = `${API_URL}/orders`;

    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };


    try {
        const response = await api.post(url, payload, { headers });

        // console.log('api ln 364 createOrder:', { response, payload, token });

        // return response.data;
        return response;

    } catch (error) {
        console.error("Error creating order:", error);
        throw error;
    }

};


export const updateOrder = async (orderId: number, payload: any) => {
    const token = localStorage.getItem('token'); // Get the stored token


    const url = `${API_URL}/orders/update/${orderId}`;
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    return api.put(url, payload, { headers });
};

export const cancelOrder = async (orderId: number) => {

    const token = localStorage.getItem('token'); // Get the stored token


    const response = await api.put(`orders/status`,
        {
            orderId: orderId,
            status: 'cancel'
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data; // Assuming the API returns a success message
};

export const deleteOrder = async (orderId: number) => {

    const token = localStorage.getItem('token'); // Get the stored token


    const response = await api.delete(`orders/delete`, {
        data: {
            orderId: orderId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });

    return response.data; // Assuming the API returns a success message
};

export const fetchAllCustomer = async () => {

    const token = localStorage.getItem('token'); // Get the stored token


    const response = await api.get(`customers/all-name-id`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data; // Assuming the API returns a success message
};


export const fetchProductsModifiers = async (
    pid: number, //product id
    add_on: number, //to list add_on products (modifier) that is product_cat = 2 (add-on)

) => {

    const token = localStorage.getItem('token'); // Get the stored token

    const url = `/products/modifiers?pid=${pid}&add_on=${add_on}`;

    const response = await api.post(url, {
        headers: {
            Authorization: `Bearer ${token}`, // Send the token in the Authorization header
        },
        // params: {
        //     pid,
        //     add_on,
        // },
    });

    // console.log('fetchProductsModifiers apip ln 501 :', response);

    return response;
};