import React, { createContext, useContext, useEffect, useState } from 'react';
import { appSettings } from '../utils/api';

interface ConfigContextProps {
    settings: Record<string, any> | null; // Replace `any` with the actual type of your settings
    loadingSettings: boolean;
    errorSettings: string | null;
    refreshSettings: () => void; // Function to refresh settings
}

const ConfigContext = createContext<ConfigContextProps | undefined>(undefined);

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [settings, setSettings] = useState<Record<string, any> | null>(null);
    const [loadingSettings, setLoadingSettings] = useState<boolean>(true);
    const [errorSettings, setErrorSettings] = useState<string | null>(null);

    const fetchSettings = async () => {
        setLoadingSettings(true);
        setErrorSettings(null);
        try {
            const fetchedSettings = await appSettings();
            // console.log('ConfigContext.tsx > fetchedSettings:', fetchedSettings);
            setSettings(fetchedSettings);
        } catch (err: any) {
            setErrorSettings(err.message || 'Failed to fetch app settings');
        } finally {
            setLoadingSettings(false);
        }
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    const refreshSettings = () => {
        fetchSettings(); // Allow manual refresh of settings
    };

    return (
        <ConfigContext.Provider value={{ settings, loadingSettings, errorSettings, refreshSettings }}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = () => {
    const context = useContext(ConfigContext);
    if (!context) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return context;
};